import {ProductInterface} from "@/slices/Products/interfaces/product.interface";

export interface OfferCategoryInterface {
    id: string;
    name: string;
    type: OfferCategoryType;
    isRequired: boolean;
    characteristics: CharacteristicInterface;
    allowDuplicates: boolean;
    allowRemoval?: boolean;
    isVisibleForClient: boolean;
    isVisibleForClientInGenerator: boolean;
    products?: ProductInterface[],
    dependantCategories?: OfferCategoryInterface[]
    calculatorName?: Calculator,
    measurement: string,
    quantity?: {
        type: string,
        value: string | number,
        enableEdit: boolean,
        category?: {
            type: string,
            reference: OfferCategoryInterface
        }
    }
}

export interface Calculator {
    name: string
}

export interface DefaultCalculationOfferCategoryData {
    products?: ProductInterface[],
    bitrixSectionId: string
    position?: any
}

export interface CalculationOfferCategoryData {
    dependantCategory: OfferCategory[]
    rangeCollection: Range[]
}

export interface FractionOfferCategoryData {
    dependantCategories: OfferCategoryInterface[];
    fraction: number,
    userEditable: boolean
}
export type DefaultCalculationOfferCategoryInterface = DefaultCalculationOfferCategoryData & OfferCategoryInterface
export type CalculationOfferCategoryInterface = CalculationOfferCategoryData & OfferCategoryInterface
export type FractionOfferCategoryInterface = FractionOfferCategoryData & OfferCategoryInterface
export type OfferCategory = DefaultCalculationOfferCategoryInterface | CalculationOfferCategoryInterface | FractionOfferCategoryInterface

export type CategoryWithPositionId<T> = {
    category: T,
    positionId?: string | null
}

export enum OfferCategoryType {
    STANDARD = 'STANDARD',
    SERVICE = 'SERVICE',
    DEPENDENT_CATEGORY_FRACTION = 'DEPENDENT_CATEGORY_FRACTION',
    CUSTOM_CALCULATION = 'CUSTOM_CALCULATION'
}

export interface CharacteristicInterface {
    id: string;
    name: string;
    bitrix?: {
        ID: string,
        CODE: string,
        PROPERTY_TYPE: string
    }
}

export type OfferCategoryFormStateInterface = {
    category: OfferCategoryInterface,
    defaultData: DefaultCalculationOfferCategoryData,
    fractionData: FractionOfferCategoryData,
    calculationData: CalculationOfferCategoryData
}

export type IncomingOfferCategoryData = {
    id: string,
    name: string,
    isRequired: boolean,
    type: OfferCategoryType,
    isVisibleForClient: boolean,
    isVisibleForClientInGenerator: boolean,
    characteristics: CharacteristicInterface,
    dependantCategories: OfferCategory[]
    fraction: number,
    userEditable: boolean,
    allowDuplicates: boolean,
    bitrixSectionId: string,
    rangeCollection: Range[],
    dependantCategory: OfferCategory[],
    measurement: string,
}

export enum RangeMultiplierType {
    NONE = 'NONE',
    CONSTANT_VALUE = 'CONSTANT_VALUE',
    TOTAL_DEPENDENT_CHARACTERISTICS_CATEGORY = 'TOTAL_DEPENDENT_CHARACTERISTICS_CATEGORY',
    TOTAL_DEPENDENT_CATEGORY_AMOUNT = 'TOTAL_DEPENDENT_CATEGORY_AMOUNT'
}

export interface Range {
    from: number,
    to: number,
    result: number,
    multiplierType: RangeMultiplierType,
    multiplierValue: number,
}
